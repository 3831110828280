<template>
  <div>
    <div class="container">
      <div class="left">
        <van-icon @click="$router.go(-1)" size=".35rem" name="arrow-left" />
        <div class="wx">您的姓名</div>
      </div>
      <!-- <div class="submit" @click="submit">保存</div> -->
    </div>
    <div class="email">
      <div>
        <input class="input" v-if="realname_auth == 2"  disabled @click="authentication" v-model="username" type="text" placeholder="姓名" />
        <input class="input" v-else v-model="username" disabled type="text" placeholder="姓名" />
      </div>
      <div>
        <van-icon v-if="realname_auth == 2" @click="authentication" size=".35rem" name="arrow" />
        <span style="color:#F55814" v-else>已实名</span>
      </div>
      
    </div>
    <div  v-if="realname_auth == 2"  class="info">实名认证后,您被企业入职的机率会更高</div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      username: "",
      realname_auth:''
    };
  },
  mounted(){
     let username = this.$route.query.username
     this.username = username
    //  let userinfo = sessionStorage.getItem("userinfo")
    //  userinfo = JSON.parse(userinfo)
     this.realname_auth =  this.$route.query.realname_auth
   
  },
  methods: {
    submit() {
      let username = this.username;
      if (username == "") {
        Toast("请输入您的名称");
        return;
      }
      this.$http
        .post("/user/v1/personal_info/editPersonalInfo", {
          reqType: "editPersonalInfo",
          username: username,
        })
        .then((res) => {
          let response = JSON.parse(res.data);
          if (response.code == 0) {
            Toast.success("保存成功");
            this.$router.push("/usermsg");
          } else {
            Toast.fail(response.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    authentication(){
      this.$router.push("/authentication")
    },
  },
 
};
</script>
<style scoped>
.info{
	width: 5.17rem;
	height: 0.33rem;
	font-size: 0.24rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #F55814;
	line-height: 0.33rem;
	margin-top:0.24rem;
	margin-left:0.5rem;
	margin-bottom:0.9rem;
}
.email {
  /* height: 1.13rem; */
  /* line-height: 1.13rem; */
  background: #ffffff;
  padding: 0 0.49rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email input {
  width:2.5rem;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  margin:0.2rem 0;
  /* line-height: 0.48rem;
  height: 0.48rem; */
}
.info {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55814;
  margin-left: 0.48rem;
  margin-top: 0.2rem;
}
.submit {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.container {
  display: flex;
  justify-content: space-between;
  height: 1rem;
  line-height: 1rem;
  background: #fff;
  padding: 0 0.32rem;
  border: 0.02rem solid #eee;
}
.left {
  display: flex;
  align-items: center;
}
.wx {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 0.3rem;
}
.input{
  background-color: #fff;
}
</style>